/* eslint-disable consistent-return */
import deleteAllCookies from '@helpers/deleteAllCookies';
import { showToast } from '@modules/app/redux/AppActions';
import RoutingService from '@services/RoutingService';
import { i18n } from 'next-i18next';
import { dispatcher } from '../../helpers/Redux';

type DataType = {
  statusCode?: number;
  message: string;
}

const ErrorMiddleware = (data: DataType) => {

  process.env.NODE_ENV === 'development' && console.log('success', data);

  let variant: 'default' | 'error' | 'success' | 'warning' | 'info' | undefined = 'warning';

  const statusCode = data?.statusCode || 400;

  if ([400, 401, 403, 500].includes(statusCode)) {

    variant = 'error';

    showToast(data?.message || i18n?.t('generalErrorText') || '', variant);

    if (statusCode === 401) {

      dispatcher({ type: 'LOGIN_USER_ERROR' });

      deleteAllCookies();

      RoutingService((routes, router) => router.push(routes.index));

    }

  } else if ([600].indexOf(statusCode) >= 0 || !statusCode) {

    return () => {

      const messages = data?.message.split('~');

      messages?.forEach((msg) => {

        showToast(msg || i18n?.t('generalErrorText') || '', variant);

      });

    };

  }

};
export default ErrorMiddleware;
