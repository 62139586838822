
export const mapToKey = (key, list = []) => list.map((item) => ({
  [key]: item[key]
}));

export const mapOnKey = (list = [], key) => list?.map((item) => item[key]);

export const required = (message = 'This field required') => {

  throw new Error(message);

};

export const emptyDataCleaner = (form) => {

  Object.keys(form).forEach((key) => {

    ['', null, undefined].indexOf(form[key]) >= 0 && delete form[key];

  });

  process.env.NODE_ENV === 'development' && console.log({ form });

  return form;

};

export const objectMapper = (object, objectPath, value) => {

  if (objectPath.length > 1 && typeof objectPath === 'string') {

    return objectMapper({ ...object }, objectPath.split('.'), value);

  }

  if (objectPath === '.' && value !== undefined) return { ...object, ...value };

  if (objectPath.length === 1 && value !== undefined) {

    object[objectPath[0]] = value;
    return object;

  }

  if (objectPath.length === 0) return object;

  return objectMapper(object[objectPath[0]], objectPath.slice(1), value);

};

export class LocalStorage {
  static get(key) {

    try {

      return this._jsonParse(localStorage.getItem(key));

    } catch (e) {

      return null;

    }

  }

  static set(key, value) {

    return localStorage.setItem(key, JSON.stringify(value));

  }

  static _jsonParse(value) {

    try {

      return JSON.parse(value);

    } catch (e) {

      return null;

    }

  }
}

// export function formDataParser(data) {

//   const FormData = require('form-data');

//   const formData = new FormData();
//   const filePaths = [];
//   const error = false;

//   Object.keys(data).forEach((key) => {

//     if (Array.isArray(data[key])) {

//       const innerArray = data[key];
//       innerArray.forEach((arrayItem, index) => {

//         if (typeof arrayItem === 'object') {

//           Object.keys(arrayItem).forEach((itemKey) => {

//             // Parsing nested objects to FomrData i.e. => ProductLangs[0].name : Opsiyon
//             formData.append(`${key}[${index}].${itemKey}`, arrayItem[itemKey]);

//           });

//         } else {

//           formData.append(`${key}[${index}]`, arrayItem);

//         }

//       });

//     } else if (key === 'image') {

//       const base64String = data[key];

//       const mimeType = base64String.match(/[^:]\w+\/[\w-+\d.]+(?=;|,)/)[0].split('/').pop();

//       const fileName = uuidv4();

//       const globalPath = process.cwd();

//       const filePath = `${globalPath}/images/${fileName}.${mimeType}`;

//       // const base64Image = base64String.split(';base64,').pop();


//       // fs.writeFileSync(filePath, base64Image, { encoding: 'base64' }, (err) => {
//       //   if (err) {
//       //     error = true;
//       //   }
//       // });

//       if (!error) {

//         filePaths.push(filePath);
//         // formData.append('image', fs.createReadStream(filePath));

//       }

//     } else {

//       formData.append(key, data[key]);

//     }

//   });
//   return { error, filePaths, formData };

// }
