import GetAcceptLanguage from '@helpers/GetAcceptLanguage';
import axios from 'axios';
import { getAppState } from '../redux/store';

const options = { baseURL: 'https://api.opsiyonerp.com/api' };

const axiosInstance = axios.create(options);

axiosInstance.interceptors.request.use((config) => {

  if (config.headers) {

    config.headers['Accept-Language'] = GetAcceptLanguage();

    const accessToken = getAppState().AppReducer?.user?.tokens?.accessToken;

    const businessId = getAppState().AppReducer?.businessId;

    if (accessToken) config.headers.authorization = `Bearer ${accessToken}`;

    if (businessId) config.headers['Ops-Tenant'] = businessId;

    config.headers['Ops-From'] = '0';

  }

  return config;

});

export default axiosInstance;

