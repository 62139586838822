import { CookieEnum } from '@assets/enums/CookieEnum';
import { CookieService } from '@services/CookieService';

export default function deleteAllCookies() {

  CookieService.removeCookie(CookieEnum.BusinessId);

  CookieService.removeCookie(CookieEnum.Opsiyon);

}
